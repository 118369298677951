import { r as requestTimeout } from './requestTimeout-825f721a.js';
import { i as isSSR } from './element-d46853d9.js';

/* eslint-disable @typescript-eslint/no-empty-function */
const _ResizeObserver = (cb) => {
  var _a;
  let ResizeObserverPolyfilledConstructor;
  if (!isSSR() && window.ResizeObserver) {
    ResizeObserverPolyfilledConstructor = window.ResizeObserver;
  }
  else {
    const ResizeObserverPolyfill = require('resize-observer-polyfill');
    if ((_a = ResizeObserverPolyfill.hasOwnProperty) === null || _a === void 0 ? void 0 : _a.call(ResizeObserverPolyfill, 'default')) {
      ResizeObserverPolyfilledConstructor = ResizeObserverPolyfill.default;
    }
    else {
      ResizeObserverPolyfilledConstructor = ResizeObserverPolyfill;
    }
  }
  return new ResizeObserverPolyfilledConstructor(cb);
};
// TODO: remove
const observeElementSize = (el, cb) => {
  const observer = _ResizeObserver(() => {
    requestTimeout(() => {
      cb();
    }, 0);
  });
  observer.observe(el);
  return {
    observer,
    removeObserver: () => observer.disconnect()
  };
};

export { _ResizeObserver as _, observeElementSize as o };
